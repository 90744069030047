<template>
  <div class="auth-form-padding">
    <h2>Envie sua mensagem</h2>
    <span>
      Você deseja estruturar uma operação?<br />Envie sua mensagem e nossa equipe entrará em<br />
      contato para prosseguir com seu atendimento.
    </span>
    <validation-observer ref="contactForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="sendContact">
        <b-form-group label="Nome" label-for="name">
          <validation-provider #default="{ errors }" name="Nome" vid="name" rules="required">
            <b-form-input
              id="name"
              v-model="contact.name"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Email" label-for="email">
          <validation-provider
            #default="{ errors }"
            name="Email"
            vid="email"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="contact.email"
              :state="errors.length > 0 ? false : null"
              name="email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Celular/whatsApp" label-for="cellphone">
          <validation-provider
            #default="{ errors }"
            name="Celular/whatsApp"
            vid="cellphone"
            rules="required"
          >
            <b-form-input
              id="cellphone"
              v-model="contact.cellphone"
              :state="errors.length > 0 ? false : null"
              name="cellphone"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Mensagem" label-for="message">
          <validation-provider
            #default="{ errors }"
            name="Mensagem"
            vid="message"
            rules="required|min:3"
          >
            <b-form-input
              id="message"
              v-model="contact.message"
              :state="errors.length > 0 ? false : null"
              name="message"
              type="text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button type="submit" variant="primary" :disabled="invalid || loading">
          {{ loading ? 'Enviando...' : 'Enviar mensagem' }}
          <b-spinner small v-if="loading" />
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BImg, BForm, BButton, BSpinner } from 'bootstrap-vue'
import { required, email, min } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      contact: {
        name: '',
        email: '',
        cellphone: '',
      },
    }
  },
  methods: {
    async sendContact() {
      try {
        this.loading = true
        const success = await this.$refs.contactForm.validate()

        if (!success) return

        await this.$store.dispatch('auth/login', {
          email: this.userEmail,
          password: this.password,
        })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-form-padding {
  padding: 8rem;
}

@media (min-width: 1401px) {
  .auth-form-padding {
    max-width: 822px;
    margin: auto;
  }
}
</style>
